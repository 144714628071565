import { Routes, Route } from "react-router-dom";
import Landing from "./components/Landing";
import Survey from "./components/Survey/Survey";
import Resources from "./components/Resources/Resources";
import ResourcesSearch from "./components/Resources/Search";
import NotFound from "./components/404";


function App() {
    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path="/" element={<Landing />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/:keyword" element={<ResourcesSearch />} />
        </Routes>
    );
}

export default App;
