import HaveringLogo from "../../imgs/brand/logo.svg"

function Footer() {
    return (
        <footer className="mt-auto py-6" role="contentinfo">
            <div className="container mx-auto">
                <div className="flex flex-col items-center justify-between lg:flex-row">
                    <p className="text-custom-dark-gray text-center text-opacity-50 lg:text-left text-sm order-2 lg:order-1">
                        Copyright © 2022 The London Borough of Havering <br />
                        <span className="block mt-4 lg:mt-0">Powered by <a href="https://hoopd.co.uk" className="text-custom-dark-gray text-opacity-50 font-semibold" rel="noreferrer" target="_blank">Hoop'd</a></span>
                    </p>
                    <figure className="mb-3 order-1 lg:order-2 lg:mb-0">
                        <a href="https://www.havering.gov.uk" rel="noreferrer" target="_blank">
                            <img src={HaveringLogo} alt="Havering logo" />
                        </a>
                    </figure>
                </div>
            </div>
        </footer>
    )
}

export default Footer
