import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom"
import IcomoonReact from "icomoon-react";
import axios from "axios";

import Header from "./Header";

import GetCookie from "../../utils/GetCookie";
import GetApiURL from "../../utils/GetApiURL";
import Input from "../Common/Input/Input";
import iconSet from "../../icomoon/selection.json";

import ResourceItem from "./ResourceItem";
import Footer from "./Footer";
import ForwardModal from "./ForwardModal";
import ResourceItemPlaceholder from "./ResourceItemPlaceholder";

function Resources() {

    let navigate = useNavigate();

    const [resources, setResources] = useState({});
    const [searchKey, setSearchKey] = useState("");
    const [isFetchingResources, setIsFetchingResources] = useState(false);
    const [forwardModalOpen, setForwardModalOpen] = useState(false);
    const [displayResourcesIds, setDisplayResourcesIds] = useState([]);

    const HandleSearchFormSubmit = (e) => {
        navigate("/resources/" + searchKey);
    }

    useEffect(() => {
        setIsFetchingResources(true);
        axios({
            method: "get",
            url: GetApiURL() + "/questionnaire/result/",
            withCredentials: true,
            headers: {
                "X-CSRFToken": GetCookie('csrftoken'),
                "token": localStorage.getItem('api_token')
            }
        })
        .then((response) => {
            setResources(response.data.results);
            setDisplayResourcesIds(Object.keys(response.data.results).map((key) => { return response.data.results[key].resources.map((resource) => { return resource.id }) }).flat())
            setIsFetchingResources(false);
        })
    }, [])

    return (
        <div className="flex flex-col min-h-screen">
            <Header setForwardModalOpen={setForwardModalOpen} />
            <main role="main">
                <div className="bg-[#fafafa] py-6 print:hidden">
                    <div className="container mx-auto px-4">
                        <form onSubmit={HandleSearchFormSubmit}>
                            <div className="relative">
                                <IcomoonReact iconSet={iconSet} className="icon absolute top-4 left-5" size={18} icon="search" />
                                <Input type="text" name="search_key" placeholder="Search in database" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} style={{paddingLeft: "50px"}} />
                                { searchKey.length > 0 && <a href="javascript:void(0)" className="absolute top-3 right-5" onClick={() => setSearchKey("") }>
                                    <IcomoonReact iconSet={iconSet} className="icon" size={12} icon="close" />
                                </a> }
                            </div>
                        </form>
                    </div>
                </div>
                <div className="py-6 print:py-1 break-before-auto">
                    <div className="container mx-auto px-4">
                        {
                            isFetchingResources && <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <ResourceItemPlaceholder />
                                <ResourceItemPlaceholder />
                            </div>
                        }
                        {
                            resources ? Object.keys(resources).map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div className="mb-8">
                                            <h3 className="font-semibold text-[22px] mb-4">{key}</h3>
                                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 print:grid-cols-3">
                                                { resources[key].resources.length === 0 && <p>No resource found in this category</p> }
                                                {
                                                    resources[key].resources.map((resource, index) => {
                                                        return <ResourceItem key={index} item={resource} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }) : <p>No results found.</p>
                        }
                    </div>
                </div>
            </main>
            <Footer />
            <ForwardModal forwardModalOpen={forwardModalOpen} displayResourcesIds={displayResourcesIds} setForwardModalOpen={setForwardModalOpen} />
        </div>
    )
}

export default Resources
