function Radio({text, name, id, value, ...inputProps}) {
    return (
        <div className="relative">
            <input type="radio" name={name} id={id} value={value} className="absolute top-[15px] left-[15px] w-6 h-6 opacity-0 peer" {...inputProps} />
            <label className="block font-semibold cursor-pointer text-white border border-white border-opacity-20 py-4 pr-4 pl-14 rounded-lg after:absolute after:inline-block after:w-[6px] after:h-[10px] after:border-b-[1px] after:border-r-[1px] after:border-custom-blue-darken after:rotate-45 after:top-[20px] after:left-[24px] after:opacity-0 after:transition-all before:absolute before:top-[15px] before:left-[15px] before:inline-block before:bg-white before:bg-opacity-20 before:rounded-full before:w-6 before:h-6 before:transition-all hover:border-opacity-40 hover:before:bg-opacity-40 peer-checked:bg-white peer-checked:text-custom-blue-darken peer-checked:before:bg-custom-yellow peer-checked:before:bg-checkmark peer-checked:after:opacity-100 transition-all" htmlFor={id}>
                { text }
            </label>
        </div>
    )
}

export default Radio
