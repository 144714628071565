import { useState, createRef } from "react";

import './Survey.scss'

import IcomoonReact from "icomoon-react";
import Slider from "react-slick";
import axios from "axios";
import { useNavigate } from "react-router-dom"

import GetCookie from "../../utils/GetCookie";
import GetApiURL from "../../utils/GetApiURL";
import Button from "../Common/Button/Button";
import iconSet from "../../icomoon/selection.json";
import HaveringLogoNegative from "../../imgs/brand/logo-negative.svg"

import { useEffect } from "react";
import QuestionItem from "./QuestionItem";
import NavigationItem from "./NavigationItem";

function Survey() {

    let navigate = useNavigate();
    let slider = createRef();
    let navigationSlider = createRef();

    const [questions, setQuestions] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [surveyFinishedRate, setSurveyFinishedRate] = useState(0);
    const [parentQuestionIndexList, setParentQuestionIndexList] = useState([]);
    const [sliderIndexList, setSliderIndexList] = useState([]);

    const sliderSettings = {
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        draggable: false,
        fade: true,
        adaptiveHeight: true,
        swipe: false,
        beforeChange: (prev, next) => {
            navigationSlider.current.slickGoTo(next)
            if(prev < next){
                setSliderIndexList(sliderIndexList.concat(prev));
            }
        }
    }

    const navSliderSettings = {
        slidesToShow: 1,
        fade: true,
        arrows: false,
        dots: false,
        infinite: false,
        mobileFirst: true,
        rows: 1
    }

    const PostAnswers = (data) => {
        data.answers = JSON.stringify(data.answers);
        let currentAnswers = JSON.parse(localStorage.getItem('user_answers'));
        currentAnswers[data.question_id] = data;
        localStorage.setItem('user_answers', JSON.stringify(currentAnswers));
        axios({
            method: "post",
            url: GetApiURL() + "/questionnaire/save_answers/",
            data: data,
            withCredentials: true,
            headers: {
                "X-CSRFToken": GetCookie('csrftoken'),
                "Content-Type": "multipart/form-data",
                "token": localStorage.getItem('api_token')
            }
        })
        .then((response) => {
            if(response.data.survey_finish){
                navigate("/resources");
            } else if(response.data.next_question){
                setParentQuestionIndexList(parentQuestionIndexList.concat(response.data.current_question_index));
                slider.current.slickGoTo(response.data.next_question.id - 1);
            }
        })
    }

    const HandleBackButtonClick = () => {
        if(sliderIndexList.length === 0){
            navigate("/");
        } else {
            slider.current.slickGoTo(sliderIndexList[sliderIndexList.length-1]);
            setSliderIndexList(sliderIndexList.splice(0,sliderIndexList.length-1));
            setParentQuestionIndexList(parentQuestionIndexList.splice(0,parentQuestionIndexList.length-1));
        }
    }

    useEffect(() => {
        setSurveyFinishedRate(Math.round((100 * parentQuestionIndexList[parentQuestionIndexList.length -1]) / totalQuestions))
    }, [parentQuestionIndexList, totalQuestions])

    useEffect(() => {
        axios({
            method: "get",
            url: GetApiURL() + "/questions/",
            withCredentials: true,
            headers: {
                "X-CSRFToken": GetCookie('csrftoken'),
                "token": localStorage.getItem('api_token')
            }
        })
        .then((response) => {
            setTotalQuestions(response.data.question_count);
            setQuestions(response.data.questions)
        })
    }, [])

    return (
        <div className="flex flex-col min-h-screen bg-custom-blue bg-survey-background bg-no-repeat bg-right-bottom">

            <header role="banner">
                <div className="h-4 bg-custom-blue-ligthen">
                    <span className="bg-custom-yellow block h-full" style={{width: surveyFinishedRate + "%"}}></span>
                </div>
                <div className="container mx-auto px-4 mt-10">
                    <div className="flex justify-between text-white">
                        <div className="w-1/2">
                            <Slider ref={navigationSlider} {...navSliderSettings}>
                                {
                                    questions.map((question) => {
                                        return <NavigationItem key={question.id} parentQuestionIndexList={parentQuestionIndexList} item={question} total={totalQuestions}  />
                                    })
                                }
                            </Slider>
                        </div>
                        <Button type="btn-outline-light" onClick={HandleBackButtonClick}>
                            <IcomoonReact iconSet={iconSet} className="icon" color="#fff" size={18} icon="chevron-left" />
                            Back
                        </Button>
                    </div>
                </div>
            </header>

            <main role="main" className="mt-auto">
                <div className="container mx-auto px-4">
                    <div>
                        <Slider ref={slider} {...sliderSettings}>
                            {
                                questions.map((question) => {
                                    return <QuestionItem key={question.id} PostAnswers={PostAnswers} data={question} />
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </main>

            <footer className="mt-auto py-6" role="contentinfo">
                <div className="container mx-auto">
                    <div className="flex flex-col items-center justify-between lg:flex-row">
                        <p className="text-white text-center text-sm order-2 lg:text-left lg:order-1">
                            Copyright © 2022 The London Borough of Havering
                            <span className="block mt-4 lg:mt-0">Powered by <a href="https://hoopd.co.uk" className="text-white font-semibold" rel="noreferrer" target="_blank">Hoop'd</a></span>
                        </p>
                        <figure className="mb-3 order-1 lg:order-2 lg:mb-0">
                            <a href="https://www.havering.gov.uk" rel="noreferrer" target="_blank">
                                <img src={HaveringLogoNegative} alt="Havering logo" />
                            </a>
                        </figure>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Survey
