import { Tooltip } from 'react-tippy'
import IcomoonReact from "icomoon-react";

import iconSet from "../../icomoon/selection.json";
import Button from "../Common/Button/Button";

function ResourceItem(props) {

    const { tags, text_description, url, service_location, information_provider } = props.item;

    return (
        <div className="flex flex-col p-6 rounded-lg border border-custom-dark-gray border-opacity-20 print:p-4">
            <div className="mb-6 print:mb-2">
                {
                    tags.map((tag, index) => {
                        return <span key={index} className="bg-custom-dark-gray inline-block bg-opacity-10 px-2 py-1 text-sm text-custom-dark-gray text-opacity-80 rounded mb-1 mr-2 print:text-[10px]">{ tag.name }</span>
                    })
                }
            </div>
            <div className="flex-auto mb-6 print:mb-2">
                { text_description && <h4 className="text-custom-dark-gray text-lg font-semibold mb-2 print:font-normal print:text-[10px] print:leading-4">{ text_description }</h4> }
                { url && <p className="text-sm break-words print:text-[10px]"><a href={url} className="text-custom-blue print:leading-2" target="_blank">{ url }</a></p> }
            </div>
            <div>
                <div className="mb-4 print:hidden">
                    <Button classes="w-full !rounded">VISIT</Button>
                </div>
                <div className="flex items-center justify-between pt-4 border-t border-t-custom-dark-gray border-opacity-10 print:block print:pt-2">
                    <span className="border border-[#ebebeb] rounded text-sm items-center flex gap-3 px-3 py-1 print:inline-flex print:text-[10px]">
                        { service_location && service_location.name === "Online" && <IcomoonReact iconSet={iconSet} className="icon !mr-0" size={14} icon="event-type-online" /> }
                        { service_location && service_location.name === "Public Location" && <IcomoonReact iconSet={iconSet} className="icon !mr-0" size={14} icon="event-type-public" /> }
                        { service_location && service_location.name === "At-Home" && <IcomoonReact iconSet={iconSet} className="icon !mr-0" size={14} icon="home" /> }
                        { service_location && service_location.name }
                    </span>
                    <span className="block text-sm text-ellipsis overflow-hidden whitespace-nowrap max-w-[45%] print:text-[10px] print:max-w-none print:mt-1">
                        { information_provider && <Tooltip title={information_provider.name} arrow="true" position="bottom" mouseenter="hover">{information_provider.name}</Tooltip> }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ResourceItem
