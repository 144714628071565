import { Link } from "react-router-dom";

import VectorRaining from "../../imgs/admin/vector-raining.svg"

function index() {

    return (
        <div className="flex items-center justify-center flex-col text-left min-h-screen">
            <div>
                <figure className="mb-6">
                    <img src={VectorRaining} alt="" />
                </figure>
                <h1 className="text-5xl font-bold text-[#c53637]">404</h1>
                <h2 className="text-5xl font-bold text-custom-blue-darken mb-6">Whoops, sorry!</h2>
                <p className="mb-6">The page you are looking for is not found.</p>
                <p><Link to="/" className="text-custom-blue underline">Back to home</Link></p>
            </div>
        </div>
    )
}

export default index
