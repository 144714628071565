import Button from "../Common/Button/Button";

function ResourceItemPlaceholder() {
    return (
        <div className="flex flex-col p-6 rounded-lg border border-custom-dark-gray border-opacity-20">
            <div className="mb-6">
                <span className="w-20 h-5 bg-gray-200 inline-block rounded-md mr-3"></span>
                <span className="w-20 h-5 bg-gray-200 inline-block rounded-md"></span>
            </div>
            <div className="flex-auto mb-6">
                <h4 className="w-full h-5 bg-gray-200 mb-3"></h4>
                <p className="w-10/12 h-5 bg-gray-200"></p>
            </div>
            <div>
                <Button classes="w-full !bg-gray-200 !rounded"></Button>
                <div className="flex items-center justify-between mt-4 pt-4 border-t border-t-custom-dark-gray border-opacity-10">
                    <span className="w-28 h-5 bg-gray-200 rounded-md"></span>
                    <span className="w-40 h-5 bg-gray-200 rounded-md"></span>
                </div>
            </div>
        </div>
    )
}

export default ResourceItemPlaceholder
