import axios from "axios";
import { useNavigate } from "react-router-dom"

import GetCookie from "../../utils/GetCookie"
import GetApiURL from "../../utils/GetApiURL";
import Button from "../Common/Button/Button";
import HaveringLogoNegative from "../../imgs/brand/logo-negative.svg"
import LandingImage from "../../imgs/landing/hero-image.png"

function Landing() {

    let navigate = useNavigate();

	console.log(GetApiURL());

    const HandleButtonStart = () => {

        axios({
            method: "post",
            url: GetApiURL() + "/questionnaire/start/",
            withCredentials: true,
            headers: {
                "X-CSRFToken": GetCookie('csrftoken')
            }
        })
        .then((response) => {
            localStorage.setItem('api_token', response.data.token);
            localStorage.setItem('user_answers', JSON.stringify({}));
            navigate("/survey");
        })

    }

    return (
        <div className="flex flex-col min-h-screen bg-custom-blue bg-landing-background bg-no-repeat bg-right-top">
            <main className="mt-auto pt-10 lg:pt-0" role="main">
                <div className="container mx-auto px-4">
                    <div className="flex items-center flex-col justify-between lg:flex-row">
                        <section className="text-white order-2 lg:max-w-lg lg:order-1">
                            <h1 className="text-4xl font-semibold mb-6 lg:text-7xl">Find Digital Support and Resources</h1>
                            <p className="text-sm mb-6 lg:text-base">This website will help you to find the digital support and resources you need to get online and improve your digital skills.</p>
                            <p className="text-sm mb-6 lg:text-base">This website is in a testing phase. Please help us improve it by sharing your <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=4Wk2NRiX-Eab7ZWvyHdsip7QJP91ZGJArCahJ3Q1YT5UMk42QTBPOFZWNjVQSUJJNkNKN1NHOVE2WC4u" className="border-b" rel="noreferrer" target="_blank">feedback</a>.</p>
                            <div className="mb-6 lg:max-w-[100px]">
                                <div className="grid">
                                    <Button onClick={HandleButtonStart}>Start</Button>
                                </div>
                            </div>
                            <p className="text-xs text-[#E0E0E0] lg:text-sm">We will ask you a few questions, this will take 2 to 3 minutes to complete.</p>
                        </section>
                        <figure className="mb-6 order-1 lg:order-2 lg:mb-0">
                            <img src={LandingImage} className="mx-auto" alt="" />
                        </figure>
                    </div>
                </div>
            </main>
            <footer className="mt-auto py-6" role="contentinfo">
                <div className="container mx-auto">
                    <div className="flex flex-col items-center justify-between lg:flex-row">
                        <p className="text-white text-center text-sm order-2 lg:text-left lg:order-1">
                            Copyright © 2022 The London Borough of Havering
                            <span className="block mt-4 lg:mt-0">Powered by <a href="https://hoopd.co.uk" rel="noreferrer" className="text-white font-semibold" target="_blank">Hoop'd</a></span>
                        </p>
                        <figure className="mb-3 order-1 lg:order-2 lg:mb-0">
                            <a href="https://www.havering.gov.uk" rel="noreferrer" target="_blank">
                                <img src={HaveringLogoNegative} alt="Havering logo" />
                            </a>
                        </figure>
                    </div>
                </div>
            </footer>
        </div>
    )

}

export default Landing
