function Checkbox({text, subtext, name, id, value, ...inputProps}) {
    return (
        <div className="relative">
            <input type="checkbox" name={name} id={id} value={value} className="absolute top-2/4 -mt-[12.5px] left-[15px] w-6 h-6 opacity-0 peer" {...inputProps} />
            <label className="block font-semibold cursor-pointer text-white border border-white border-opacity-20 py-4 pr-4 pl-14 rounded-lg after:absolute after:inline-block after:w-[6px] after:h-[10px] after:border-b-[1px] after:border-r-[1px] after:border-custom-blue-darken after:rotate-45 after:top-1/2 after:-mt-[6.5px] after:left-[24px] after:opacity-0 after:transition-all before:absolute before:top-2/4 before:-mt-[12.5px] before:left-[15px] before:inline-block before:border before:border-white before:border-opacity-20 before:rounded-md before:w-6 before:h-6 before:transition-all hover:border-opacity-40 hover:before:border-opacity-40 peer-checked:bg-white peer-checked:text-custom-blue-darken peer-checked:before:bg-custom-yellow peer-checked:before:bg-checkmark peer-checked:after:opacity-100 transition-all" htmlFor={id}>
                { text }
                { subtext && <span className="text-[#a7aab3] text-sm block font-normal">({subtext})</span> }
            </label>
        </div>
    )
}

export default Checkbox
