function NavigationItem(props) {

    if(props.item.intervention_category) {
        return (
            <>
                Part <strong className="font-semibold">{props.parentQuestionIndexList[props.parentQuestionIndexList.length -1]}</strong> of <strong className="font-semibold">{ props.total }</strong>
                <strong className="block font-semibold">{props.item.intervention_category.name}</strong>
            </>
        )
    } else {
        return (
            <>
                <strong className="font-semibold"></strong><strong className="font-semibold"></strong>
                <strong className="block font-semibold"></strong>
            </>
        )
    }


}

export default NavigationItem
