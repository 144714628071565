import { Fragment, useState, useEffect } from "react";

import Input from "../Common/Input/Input";
import Radio from "../Common/Radio/Radio";
import Checkbox from "../Common/Checkbox/Checkbox";

import Button from "../Common/Button/Button";
import VectorQuestion from "../../imgs/survey/vector-notebook.svg"
import HeroImage from "../../imgs/survey/hero-image.png"


function QuestionItem(props) {

    const { id, body, info, answers, form_type, page_type } = props.data;

    const checkLocalStorage = JSON.parse(localStorage.getItem('user_answers'))[id]

    const [textAnswer, setTextAnswer] = useState((checkLocalStorage && checkLocalStorage.text_answer) || "");
    const [userAnswers, setUserAnswers] = useState((checkLocalStorage && JSON.parse(checkLocalStorage.answers)) || []);
    const [uniqueItemId, setUniqueItemId] = useState(null);

    const HandleTextboxChange = (e) => {
        setTextAnswer(e.target.value)
    }

    const HandleRadioChange = (e) => {
        setUserAnswers([{
            id: Number(e.target.value)
        }])
    }

    const HandleCheckboxChange = (e) => {

        let id = Number(e.target.value);
        let isUnique = JSON.parse(e.target.getAttribute('data-unique'));

        if(e.target.checked){
            if(isUnique){
                setUserAnswers([{ id: id }]);
            } else {
                setUserAnswers([...userAnswers, { id: id }]);
            }
        } else {
            setUserAnswers(userAnswers.filter((answer) => {
                return answer.id !== id
            }))
        }

    }

    const HandleClickButtonContinue = () => {

        const data = {
            question_id: id,
            answers: userAnswers,
            text_answer: textAnswer,
            get_next_question: true
        }

        props.PostAnswers(data);

    }

    useEffect(() => {
        answers.forEach((answer) => {
            if(answer.unique){
                setUniqueItemId(answer.id);
            }
        })
    }, [answers])

    useEffect(() => {
        if(userAnswers.length > 1 && userAnswers.find(item => item.id === uniqueItemId)){
            setUserAnswers(userAnswers.filter((answer) => {
                return answer.id !== uniqueItemId
            }))
        }
    }, [userAnswers, uniqueItemId, setUserAnswers])


    if(page_type === "QUESTION_VIEW"){

        return (
            <div className="flex justify-between flex-col items-center lg:flex-row">
                <div className="max-w-lg text-white mb-5 lg:mb-0">
                    <figure className="mb-6">
                        <img src={VectorQuestion} alt="" />
                    </figure>
                    <h3 className="text-[36px] tracking-[-1.5px] leading-tight font-bold mb-6 lg:mb-10 lg:tracking-[-2.4px] lg:text-[56px]">
                        { body }
                    </h3>
                    { info && <p className="text-sm lg:text-base">{info.split('\n').map((item, key) => { return <Fragment key={key}>{item}<br/></Fragment>})}</p> }
                </div>
                <div className="max-w-[520px] w-full order-1 lg:order-2">
                    {
                        // eslint-disable-next-line
                        answers.map((answer) => {
                            if(form_type === "RADIO"){
                                return <div key={answer.id} className="mb-3">
                                    <Radio {...(userAnswers.find(item => item.id === answer.id) && { checked: true })} text={answer.body} name={`q-` + id} id={`q-` + id + `a` + answer.id} value={answer.id} onChange={HandleRadioChange} />
                                </div>
                            } else if(form_type === "CHECKBOX") {
                                return <div key={answer.id} className="mb-3">
                                    <Checkbox {...(userAnswers.find(item => item.id === answer.id) && { checked: true })} text={answer.body} subtext={answer.info} name={`q-` + id} id={`q-` + id + `a` + answer.id} value={answer.id} data-unique={answer.unique} onChange={HandleCheckboxChange} />
                                </div>
                            }
                        })
                    }
                    <div className="flex justify-end">
                        <Button onClick={HandleClickButtonContinue} classes="w-full lg:w-auto">Continue</Button>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div className="flex justify-between flex-col items-center lg:flex-row">
                <div className="max-w-2xl text-white order-2 lg:order-1">
                    <h3 className="text-[36px] max-w-lg leading-tight font-bold mb-6 lg:mb-10 lg:text-[56px]">
                        { body }
                    </h3>
                    <div className="flex gap-6 flex-col mb-10 lg:gap-3 lg:flex-row">
                        <div className="lg:w-[180px]">
                            <Input type="text" name="postcode" onChange={HandleTextboxChange} value={textAnswer} />
                        </div>
                        <div className="flex justify-end">
                            <Button onClick={HandleClickButtonContinue} classes="w-full lg:w-auto">Continue</Button>
                        </div>
                    </div>
                    { info && <p className="text-sm text-[#E0E0E0]">{info.split('\n').map((item, key) => { return <Fragment key={key}>{item}<br/></Fragment>})}</p> }
                </div>
                <div className="max-w-[520px] w-full order-1 lg:order-2 mb-8 lg:mb-0">
                    <figure>
                        <img src={HeroImage} alt="" />
                    </figure>
                </div>
            </div>
        )

    }

}

export default QuestionItem
