import { useState } from "react";

import IcomoonReact from "icomoon-react";
import Modal from 'react-modal';
import axios from "axios";

import iconSet from "../../icomoon/selection.json";

import GetCookie from "../../utils/GetCookie";
import GetApiURL from "../../utils/GetApiURL";
import Input from "../Common/Input/Input";
import Button from "../Common/Button/Button";
import VectorEmail from "../../imgs/resources/vector-email-forward.svg"
import VectorEmailSuccess from "../../imgs/resources/vector-mail-forward-success.svg"

function ForwardModal(props) {

    const [forwardEmail, setForwardEmail] = useState("");
    const [forwardModalSuccess, setForwardModalSuccess] = useState(false);

    Modal.setAppElement('#root');

    const customModalStyles = {
        overlay: {
            background: "rgb(34, 43, 64, .7)"
        }
    }

    const HandleForwardFormSubmit = (e) => {
        axios({
            method: "post",
            url: GetApiURL() + "/questionnaire/send_as_email/",
            data: { email: forwardEmail, resource_id_list: JSON.stringify(props.displayResourcesIds) },
            withCredentials: true,
            headers: {
                "X-CSRFToken": GetCookie('csrftoken'),
                "Content-Type": "multipart/form-data",
                "token": localStorage.getItem('api_token')
            }
        })
        .then((response) => {
            if(response.data.status){
                setForwardModalSuccess(true);
                setForwardEmail("");
            }
        })
        e.preventDefault();
    }

    return (
        <Modal className="w-full absolute bg-white max-w-[600px] h-full px-7 pt-9 pb-14 top-1/2 left-1/2 p-[36px 20px 60px] inset-[50% auto auto 50%] right-auto bottom-auto -mr-[50%] -translate-x-2/4 -translate-y-2/4 md:px-14 md:pt-9 md:pb-14 md:h-auto" isOpen={props.forwardModalOpen} onRequestClose={() => props.setForwardModalOpen(false)} onAfterClose={ () => setForwardModalSuccess(false) } style={customModalStyles}>
            <div className="flex justify-end mb-7">
                <button className="w-4 h-4" onClick={() => props.setForwardModalOpen(false)}>
                    <IcomoonReact iconSet={iconSet} size={12} className="!align-top" icon="close" />
                </button>
            </div>
            { !forwardModalSuccess && <div className="max-w-[480px] mx-auto">
                <form onSubmit={HandleForwardFormSubmit}>
                    <figure className="flex justify-center mb-5">
                        <img src={VectorEmail} alt="" />
                    </figure>
                    <div className="mb-10">
                        <Input type="text" name="email" placeholder="Enter email address" value={forwardEmail} onChange={(e) => setForwardEmail(e.target.value)} />
                    </div>
                    <div className="flex justify-center">
                        <Button>Send</Button>
                    </div>
                </form>
            </div> }
            { forwardModalSuccess && <div className="max-w-[480px] mx-auto">
                <figure className="flex justify-center mb-5">
                    <img src={VectorEmailSuccess} alt="" />
                </figure>
                <div className="text-center text-custom-blue-darken mb-10">
                    <p>Resources have been successfully sent to your email address.</p>
                </div>
                <div className="flex justify-center">
                    <Button type="btn-outline-gray" onClick={() => props.setForwardModalOpen(false) }>Close</Button>
                </div>
            </div> }

        </Modal>
    )
}

export default ForwardModal
