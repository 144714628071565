import IcomoonReact from "icomoon-react";
import { useNavigate } from "react-router-dom"

import Button from "../Common/Button/Button";
import iconSet from "../../icomoon/selection.json";

function Header(props) {

    let navigate = useNavigate();

    const HandleStartOverClick = () => {
        localStorage.removeItem('api_token');
        localStorage.setItem('user_answers', JSON.stringify({}));
        navigate("/");
    }

    const HandlePrintClick = () => {
        window.print();
    }

    return (
        <header className="pt-11 pb-4 print:pt-0" role="banner">
            <div className="container mx-auto px-4">
                <h1 className="font-bold text-2xl text-custom-blue-darken mb-3 lg:text-4xl">Useful resources</h1>
                <div className="flex flex-col justify-between gap-4 lg:flex-row lg:gap-0">
                    <p className="text-[#A7AAB3] text-sm max-w-[435px]">This resource list has been created depending on your answers. You may use the search function to find more resources.</p>
                    <ul className="flex gap-2 justify-center lg:justify-end print:hidden">
                        <li>
                            <Button onClick={HandleStartOverClick}>
                                Start over
                            </Button>
                        </li>
                        <li>
                            <Button type="btn-gray" onClick={() => navigate("/survey")}>
                                <IcomoonReact iconSet={iconSet} className="icon !mr-0" size={18} icon="change" />
                                <span className="hidden lg:inline lg:ml-3">Change answers</span>
                            </Button>
                        </li>
                        <li>
                            <Button type="btn-gray" onClick={HandlePrintClick}>
                                <IcomoonReact iconSet={iconSet} className="icon !mr-0" size={18} icon="print" />
                            </Button>
                        </li>
                        <li>
                            <Button type="btn-gray" onClick={() => props.setForwardModalOpen(true)}>
                                <IcomoonReact iconSet={iconSet} className="icon !mr-0" size={18} icon="mail" />
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header
