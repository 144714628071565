import { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import IcomoonReact from "icomoon-react";
import axios from "axios";

import Header from "./Header";

import GetCookie from "../../utils/GetCookie";
import GetApiURL from "../../utils/GetApiURL";
import Input from "../Common/Input/Input";
import iconSet from "../../icomoon/selection.json";

import ResourceItem from "./ResourceItem";
import Footer from "./Footer";
import ForwardModal from "./ForwardModal";
import ResourceItemPlaceholder from "./ResourceItemPlaceholder";

function Search() {

    let params = useParams();
    let navigate = useNavigate();

    const [searchKey, setSearchKey] = useState(params.keyword);
    const [searchResult, setSearchResult] = useState("");
    const [searchedKey, setSearchedKey] = useState("");
    const [isFetchingResources, setIsFetchingResources] = useState(false);
    const [forwardModalOpen, setForwardModalOpen] = useState(false);
    const [displayResourcesIds, setDisplayResourcesIds] = useState([]);

    const HandleSearchFormSubmit = (e) => {
        PostSearchResourcesByKey();
        navigate("/resources/" + searchKey)
        e.preventDefault();
    }

    const PostSearchResourcesByKey = () => {
        setIsFetchingResources(true);
        setSearchResult("");
        axios({
            method: "get",
            url: GetApiURL() + "/resources/",
            withCredentials: true,
            data: { keyword: searchKey },
            headers: {
                "X-CSRFToken": GetCookie('csrftoken'),
                "token": localStorage.getItem('api_token')
            }
        })
        .then((response) => {
            setSearchedKey(searchKey);
            setSearchResult(response.data);
            setIsFetchingResources(false);
            setDisplayResourcesIds(response.data.results.map((resource) => { return resource.id }))
        })
    }

    useEffect(() => {
        PostSearchResourcesByKey()
    }, [])

    return (
        <div className="flex flex-col min-h-screen">
            <Header setForwardModalOpen={setForwardModalOpen} />
            <main role="main">
                <div className="bg-[#fafafa] py-6 print:p-0 print:bg-transparent">
                    <div className="container mx-auto px-4">
                        <form onSubmit={HandleSearchFormSubmit} className="print:hidden">
                            <div className="relative">
                                <IcomoonReact iconSet={iconSet} className="icon absolute top-4 left-5" size={18} icon="search" />
                                <Input type="text" name="search_key" placeholder="Search in database" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} style={{paddingLeft: "50px"}} />
                                { searchKey.length > 0 && <a href="javascript:void(0)" className="absolute top-3 right-5" onClick={() => setSearchKey("") }>
                                    <IcomoonReact iconSet={iconSet} className="icon" size={12} icon="close" />
                                </a> }
                            </div>
                        </form>
                        <p className="text-custom-dark-gray mt-2">
                            { searchResult && <Fragment><strong>{ searchResult.count }</strong> results found for <strong>{ searchedKey }</strong></Fragment> }
                        </p>
                    </div>
                </div>
                <div className="py-6">
                    <div className="container mx-auto px-4">
                        {
                            isFetchingResources && <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <ResourceItemPlaceholder />
                                <ResourceItemPlaceholder />
                            </div>
                        }
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 print:grid-cols-3">
                            {
                                (searchResult && !isFetchingResources) && searchResult.results.map((resource, index) => {
                                    return <ResourceItem key={index} item={resource} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <ForwardModal forwardModalOpen={forwardModalOpen} displayResourcesIds={displayResourcesIds} setForwardModalOpen={setForwardModalOpen} />
        </div>
    )
}

export default Search
