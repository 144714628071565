import './Button.scss'

function Button({children, type, classes, ...buttonProps}) {
    return (
        <button className={`btn ` + type + ` ` + classes} {...buttonProps}>
            { children }
        </button>
    )
}

Button.defaultProps = {
    type: "btn-primary",
    classes: ""
}

export default Button
