const GetApiURL = () => {

	let url = '/api/v1';

	if(window.location.host.indexOf('localhost') > -1){
		url = 'http://dev.hoopd.co.uk/api/v1'
	}

	return url;

}

export default GetApiURL;
